import request from '@/auth/jwt/request'

/**
 * @description 网站页面 -- 网站页面列表
 * @param {Object} param params {Object} 传值参数
 */
export const pagesListApi = params => { return request('website-info/list', 'get', params) }

/**
 * @description 网站页面 -- 增加修改网站页面
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const pagesSaveApi = data => { return request('website-info/save', 'post', {}, data) }

/**
 * @description 网站页面 -- 网站页面状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const pagesStatusApi = params => { return request('website-info/status', 'get', params) }

/**
 * @description 网站页面 -- 网站页面详情
 * @param {Object} param params {Object} 传值参数
 */
export const pagesInfoApi = params => { return request('website-info/info', 'get', params) }
/**
 * @description 网站页面 -- 删除网站页面
 * { id = x }
 */
export const pagesDeleteApi = data => { return request('website-info/delete', 'delete', data) }